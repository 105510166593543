import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Caption",
  "type": "Typography",
  "icon": "volumeDownLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Caption`}</strong>{` renders a `}<inlineCode parentName="p">{`span`}</inlineCode>{` element and is used for small text rendering in the
application. As a typography component it uses the
`}<a parentName="p" {...{
        "href": "https://fonts.google.com/specimen/Poppins"
      }}>{`Poppins`}</a>{` as a font. There
are no variations for the `}<strong parentName="p">{`Caption`}</strong>{` component, but it will forward all
props provided to it's root `}<inlineCode parentName="p">{`span`}</inlineCode>{` element, so it is customisable.`}</p>
    <p><strong parentName="p">{`Caption`}</strong>{` component doesn't include any spacing between, so make sure there is one
present.`}</p>
    <hr></hr>
    <h1>{`Example`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true",
        "live": "true"
      }}>{`<>
  <Caption>Level 1 / Regular</Caption>
  <Separator />
  <Caption variant="bold">Level 1 / Bold</Caption>
  <Separator />
  <Caption level="2">Level 2 / Regular</Caption>
  <Separator />
  <Caption level="2" variant="medium">
    Level 2 / Medium
  </Caption>
  <Separator />
  <Caption level="2" variant="bold">
    Level 2 / Bold
  </Caption>
</>
`}</code></pre>
    <h1>{`Props`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`level`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"1"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"2"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"1"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"regular"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"medium"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"bold"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"regular"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The thickness of the text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the caption`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      